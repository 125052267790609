(function () {
  'use strict';

  /* @ngdoc object
   * @name public
   * @description
   *
   */
  angular
    .module('neo.public', [
      'ui.router',
      'neo.public.calendar',
      'neo.public.classification','neo.public.classificacio'
      ,'neo.public.resultats'
    ]);
}());
